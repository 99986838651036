html {
    scroll-behavior: smooth;
}

html, body {
    padding: 0px;
    margin:0px;
    height: 100% !important;
}

body {
    margin: 0;
}

#root {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    --primary-color: #4ba540;
    --border-radius: 10px;
    font-family: 'Lato', sans-serif;
    box-sizing: border-box;
    --text-color: #000;
    color: #000;
    font-size: 14px;
}

.ui-widget {
    font-family: 'Lato', sans-serif;
}

hr {
    border: solid 1px #e0e0e0;
}

a {
    text-decoration: none;
    color: inherit;
}

.text-xss {
    font-size: 0.5rem !important;
}

.mw-100 {
    max-width: 100vw
}

.tk-bg-white {
    background-color: #ffffff;
}

.p-card {
    border-radius: 10px !important;
}

.word-wrap {
    word-break: break-word;
}

h3 {
    font-size: 1.4em;
}
p {
    font-family: "Lato", sans-serif
}
span {
    font-family: "Lato", sans-serif
}
