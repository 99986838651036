@use "./../../../infecto-lms-webapp-impl/components/video-player/controllerStyle";
$lightBackground: #f3f5f8;
$darkBlue: #031d3c;
$waterBlue: #184276;
$keyBlue: #247ac7;
$keyBluePaedia: #0099cc;
$keyBlue30: #a7cbe8;
$keyBlue20: #d3e5f4;
$waterBlue10: #e7ebf1;
$waterBlue20: #d0d9e3;
$waterBlue40: #a2b3c8;
$waterBlue80: #466791;
$keyGreen: #4ace7f;
$keyGreen20: #dcf8e7;
$orange: #ea561c;
$orange20: #fedecc;
$white: #ffffff;
$gray: #CCCCCC;
$black: #000000;


.video-player {
  display: flex;

  justify-content: center;
  align-items: center;

  &.fullscreen {
    padding: 0;
  }

  .player-wrapper {
    position: relative;
  }

  .video-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    padding: 25px;

    &.stopped {
      background-color: rgba($darkBlue, 0.2);
    }

    .play-button {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 20%;
      }
    }

    .lower-controls {
      display: flex;

      .timestamp {
        color: $white;
        white-space: nowrap;
      }

      .rate {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        margin-left: auto;
        border: 1px solid $white;
        border-radius: 15px;
        background-color: transparent;
        color: $white;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        padding: 2px 5px;
        text-align-last: center;

        &:focus {
          outline: none;
        }
      }

      .fullscreen {
        height: 20px;
        width: 20px;
        display: flex;

        img {
          height: 100%;
          width: 100%;
        }
      }
    }

    .seek {
      display: flex;
      align-items: center;
      width: 100%;
      height: 20px;

      .bar {
        height: 5px;
        display: flex;
        width: 100%;
        align-items: center;

        .bar-fill {
          background-color: controllerStyle.$controllerVideoBarColor;
          height: 5px;
        }

        .bar-remain {
          background-color: $gray;
          height: 5px;
          width: 100%;
        }

        .bubble {
          z-index: 1;
          height: 16px;
          width: 16px;
          margin-left: -8px;
          margin-right: -8px;
          border-radius: 10px;
          background-color: controllerStyle.$controllerVideoBarColor;
        }
      }
    }
  }
}
